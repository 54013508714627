import { SignalDispatcher } from "ste-signals";
import AnybillCouponing from "~/additionalModels/apps/AnybillCouponing";
import AnybillExtendedDashboard from "~/additionalModels/apps/AnybillExtendedDashboard";
import AnybillNewsletter from "~/additionalModels/apps/AnybillNewsletter";
import AnybillVendorReceipts from "~/additionalModels/apps/AnybillVendorReceipts";
import ContentManagement from "~/additionalModels/apps/ContentManagement";
import CustomerMails from "~/additionalModels/apps/CustomerMails";
import InstalledAppDto from "~/models/InstalledAppDto";

// TODO make this type safe
export const useInstalledAppsModule = defineStore("installedApps", () => {
  const apps = ref<InstalledAppDto[]>([]);
  const loading = ref(false);
  const _onPopulated: SignalDispatcher = new SignalDispatcher();

  // const initialized = computed(() => !!apps.value.length);
  const initialized = ref(false);
  const onPopulated = computed(() => _onPopulated.asEvent());

  const hasNewsletterModule = computed(
    () => {
      return apps.value.find(a => a.identifier === AnybillNewsletter.identifier) != null;
    },

  );

  const hasExtendedDashboardModule = computed(
    () => {
      return apps.value.find(a => a.identifier === AnybillExtendedDashboard.identifier) != null;
    },

  );

  const hasContentManagement = computed(
    () => {
      return apps.value.find(a => a.identifier === ContentManagement.identifier) ?? false;
    },

  );

  const hasContentManagementPlus = computed(
    () => {
      const candidate = apps.value.find(a => a.identifier === ContentManagement.identifier);
      return candidate?.variant === "Upgrade";
    },
  );

  const hasAnybillCouponing = computed(() => !!apps.value.find(a => a.identifier === AnybillCouponing.identifier));

  const hasAnybillEmailMarketing = computed(() => !!apps.value.find(a => a.identifier === CustomerMails.identifier));

  const isExternalEmailService = computed(() => {
    const config = apps.value.find(
      (a: InstalledAppDto) => a.identifier === CustomerMails.identifier,
    );
    return config?.variant === "External";
  });

  const hasAnybillVendorReceipts = computed(() => {
    const vendorReceiptsApp = apps.value.find(
      (a: InstalledAppDto) =>
        a.identifier === AnybillVendorReceipts.identifier,
    );
    return vendorReceiptsApp && (vendorReceiptsApp as any).behavior !== "Ftp";
  });

  async function populate(newApps: InstalledAppDto[]) {
    newApps = ModelKeyConverter.keyToLowerCamel(newApps);
    loading.value = true;

    newApps = newApps.map(a => new InstalledAppDto(a));
    apps.value = newApps;
    _onPopulated.dispatchAsync();
    initialized.value = true;
    loading.value = false;
  }

  return {
    apps,
    loading,
    initialized,
    onPopulated,
    hasContentManagement,
    hasContentManagementPlus,
    hasExtendedDashboardModule,
    hasNewsletterModule,
    hasAnybillCouponing,
    hasAnybillEmailMarketing,
    isExternalEmailService,
    hasAnybillVendorReceipts,
    populate,
  };
});
