import type UserInfoDto from "~/models/UserInfoDto";
import type VendorCustomerUserInfoDto from "~/models/VendorCustomerUserInfoDto";
import { usePricingModule } from "~/stores/data/pricing";

interface ModuleWrapper {
  precall?: Function;
  module: any;
  args?: any;
  callback?: Function;
  disable?: boolean;
}

/**
 * Dispatch the modules and populate the stores.
 * Requires the individual modules to have a `populate` method
 *
 * @param isVend Bool value if its a vendor
 * @param isDist Bool value if its a distributorCustomer
 * @param userData user data
 *
 * !
 * @deprecated Please use useAsyncData directly in the page required
 */
export async function dispatchModuleLoad(isVend: boolean, isDist: boolean, userData: UserInfoDto | VendorCustomerUserInfoDto): Promise<void> {
  const modules: ModuleWrapper[] = [
    { module: useCategoryModule() },
    { module: usePortalUsersModule(), disable: isDist },
    { module: usePricingModule(), disable: !isVend },
    { module: useMarketingMailsModule(), disable: !isVend },
  ];

  // modules that have to be loaded in advance
  const userModule = useUserModule();
  userModule.setUser(userData);
  await userModule.populate();
  // asynchronously loadable module

  const installedAppsModule = useInstalledAppsModule();
  if (userModule.isVendorCustomer)
    await installedAppsModule.populate((userData as VendorCustomerUserInfoDto).installedApps ?? []);

  await dispatchModuleLoadAsync(modules);
}

async function dispatchModuleLoadAsync(modules: ModuleWrapper[]) {
  // filter for wanted modules
  const toLoad = modules.filter(
    m => !m.module.initialized && !m.module.loading && !m.disable,
  );

  // filter for preCalls
  const preCalls = toLoad.filter(m => !!m.precall).map(m => m.precall);

  // filter for callbacks
  const callbacks = toLoad.filter(m => !!m.callback).map(m => m.callback);

  await Promise.all(preCalls.map(p => p!()));

  await Promise.all(toLoad.map((m: ModuleWrapper) => {
    return m.module.populate(m.args);
  }));

  await Promise.all(callbacks.map(c => c!()));
}
