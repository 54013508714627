import type StoreDto from "~/models/StoreDto";
import { type AnybillResult, AnybillResultHelper } from "~/additionalModels/AnybillResponse";
import EndpointPaths from "~/config/constants/EndpointPaths";
import BaseService from "~/services/BaseService";

/**
 * Provider for all display ad (formerly known as Content Areas) things
 */
export class StoreProvider {
  private static _instance: StoreProvider;

  static get instance(): StoreProvider {
    if (StoreProvider._instance === undefined)
      StoreProvider._instance = new StoreProvider();

    return StoreProvider._instance;
  }

  /**
   * Get stores of the vendor customer
   *
   * Default take is 100
   * @returns A list of display ads
   */
  public async getStores(_params?: {
    skip: number;
    take: number;
  }): Promise<AnybillResult<StoreDto[]>> {
    return BaseService.Instance.get<StoreDto[]>(
      { path: EndpointPaths.STORE_PATH, query: _params },
    );
  }
}
