import type ActiveStoreDto from "~/models/ActiveStoreDto";

import type CreateStoreDto from "~/models/CreateStoreDto";
import type StoreDto from "~/models/StoreDto";
import ld from "lodash";
import { type AnybillResult, AnybillResultHelper } from "~/additionalModels/AnybillResponse";
import AccessGroupCustomerEnum from "~/models/AccessGroupCustomerEnum";
import CreateStoreDetailDto from "~/models/CreateStoreDetailDto";
import StoreDetailDto from "~/models/StoreDetailDto";
import { StoreProvider } from "~/provider/StoreProvider";
import { VendorCustomerProvider } from "~/provider/VendorCustomerProvider";
import { storeIconUpload } from "~/utils/UploadImageService";

// TODO Rework this with provider logic
export const useStoreModule = defineStore("store", () => {
  const userModule = useUserModule();
  const stores = ref<StoreDto[] | null>(null);
  const activeStores = ref<ActiveStoreDto[] | null>(null);
  const unavailable = ref<boolean | null>(null);
  const loading = ref(false);
  const skip = ref(0);
  // indicates wheter populateAll is running or not
  const allLoading = ref(false);
  const totalStoreCount = ref(0);

  // local stores store-dialog
  const currentStore = ref<StoreDto | null>(null);
  const storeCreationLogo = ref<File | null>(null);
  const isEdit = ref(false);
  const initialized = computed(() => {
    return !!stores.value && !!activeStores.value;
  });

  const hasStores = computed(() => {
    return (stores?.value ?? []).length > 0 || unavailable.value;
  });

  const _hasReadrights: any = computed<boolean>(() => {
    return (userModule.userRights?.get(AccessGroupCustomerEnum.Store) ?? 0) >= 1;
  });

  function setSortedStores(partnerRestriction: any) {
    const notSelected = stores.value?.filter(s => !partnerRestriction?.storeIds?.includes(s.id));
    const selected = stores.value?.filter(s => partnerRestriction?.storeIds?.includes(s.id));
    if (selected?.length === 0)
      stores.value?.sort((a: any, b) => a.storeDetail.displayName.localeCompare(b.storeDetail.displayName));
    // this.SET_STORES(this.stores);

    if (selected && selected?.length !== 0 && notSelected && notSelected?.length !== 0) {
      selected?.sort((a: any, b) => a.storeDetail.displayName.localeCompare(b.storeDetail.displayName));
      notSelected?.sort((a: any, b) => a.storeDetail.displayName.localeCompare(b.storeDetail.displayName));
      stores.value = [...selected, ...notSelected];
    }
  }

  function reset() {
    stores.value = null;
    unavailable.value = false;
  }

  async function populate({
    skip = 0,
    preserve = false,
  }: {
    skip?: number;
    preserve?: boolean;
  } = { skip: 0, preserve: false }) {
    if (stores.value?.length === totalStoreCount.value)
      return; // leave early if already populated by populateAll

    if (!_hasReadrights.value) {
      unavailable.value = true;
      return;
    }
    loading.value = true;

    // get active stores
    // const activeStores = await new StoreActivestoresService().get(`${skip}`, "100");
    const activeStoresRes = await useTypedFetch<AnybillResult<ActiveStoreDto[]>>(`/storeActivestoresService/get?skip=${skip}&take=100`);
    if (activeStoresRes.success)
      activeStores.value = activeStoresRes.value;

    // Todo: process setSelectedIds in BillStatisticsModule
    // if (!preserve) {
    //   totalStoreCount.value = _totalCount;
    //   stores.value = _stores;
    // }
    // else {
    //   _summarizeStores(_stores);
    // }
    loading.value = false;
  }

  async function populateAllStores() {
    allLoading.value = true;

    const take = 200;
    stores.value = [];
    const firstBatch = await StoreProvider.instance.getStores({ skip: 0, take });
    if (firstBatch.success) {
      const totalCount = firstBatch.headers?.totalCount ?? 0;
      stores.value = firstBatch.value;

      if (totalCount > take) {
        for (let i = take; i < totalCount; i += take) {
          const batch = await StoreProvider.instance.getStores({ skip: i, take });
          if (batch.success) {
            stores.value = stores.value!.concat(batch.value);
          }
          else {
            await AnybillLogger.instance.error(
              "Population call failed for getting stores",
              batch instanceof Error ? batch : new Error(batch.toString()),
              "stores store",
            );
          }
        }
      }
    }
    else {
      await AnybillLogger.instance.error(
        "Population call failed for getting stores",
        firstBatch instanceof Error ? firstBatch : new Error(firstBatch.toString()),
        "stores store",
      );
    }

    allLoading.value = false;
  }

  async function getAllStores() {
    allLoading.value = true;

    const take = 200;
    let storesToReturn: StoreDto[] = [];

    const firstBatch = await StoreProvider.instance.getStores({ skip: 0, take });
    if (firstBatch.success) {
      const totalCount = firstBatch.headers?.totalCount ?? 0;
      storesToReturn = firstBatch.value;

      if (totalCount > take) {
        for (let i = take; i < totalCount; i += take) {
          const batch = await StoreProvider.instance.getStores({ skip: i, take });
          if (batch.success) {
            storesToReturn = storesToReturn!.concat(batch.value);
          }
        }
      }
    }

    return storesToReturn;
  }

  async function createStore(createStoreDto: CreateStoreDto, vendorCustomerId: string | undefined, image: File | null): Promise<AnybillResult<void>> {
    let storeId;
    let res;
    if (vendorCustomerId) {
      res = await VendorCustomerProvider.instance.createStore(vendorCustomerId, createStoreDto);

      if (res.success) {
        const storeId = res.value.id;
        const object = await VendorCustomerProvider.instance.getSingleStore(vendorCustomerId, storeId);

        if (object.success) {
          _summarizeStores([object.value]);
          totalStoreCount.value++;
          return AnybillResultHelper.success(void {}, 200);
        }
        else {
          await AnybillLogger.instance.warn(
            "Population call failed after creating a store",
            res instanceof Error ? res : new Error(res.toString()),
            "stores store",
          );
        }
        storeCreationLogo.value = null;
        currentStore.value = null;
        return AnybillResultHelper.failure(null, 0, null);
      }
      else {
        await AnybillLogger.instance.error(
          "POST call failed during store creation",
          res instanceof Error ? res : new Error(res.toString()),
          "stores store",
        );
        return AnybillResultHelper.failure(null, 0, null);
      }
    }
    else {
      res = await useTypedFetch<AnybillResult<StoreDto>>("/storeService/post", createStoreDto);

      if (res.success) {
        storeId = res.value.id;

        // add companylogo
        if (!!image && !vendorCustomerId)
          await _uploadCompanyLogo(storeId, image);

        // update storelist
        const single = await useTypedFetch<AnybillResult<StoreDto>>("/storeSpecificService/get", { storeId });
        if (single.success)
          _summarizeStores([single.value]);

        totalStoreCount.value++;
        return AnybillResultHelper.success(void {}, 200);
      }
      else {
        await AnybillLogger.instance.error(
          "POST call failed during store addition",
          res instanceof Error ? res : new Error(res.toString()),
          "stores store",
        );
        return AnybillResultHelper.failure(null, 0, null);
      }
    }
  }

  /**
   * add store data
   * If id is set cusstomer is activated
   */
  async function addStore({ id, image }: { id: string | undefined | null; image: File | null }) {
    let storeId = "";
    let res: any;
    // create store
    if (id) {
      res = await useTypedFetch<AnybillResult<StoreDto>>("/vendorcustomerSpecificStoreService/post", {
        body: currentStore.value,
        vendorCustomerId: id,
      });
    }
    else {
      res = await useTypedFetch<AnybillResult<StoreDto>>("/storeService/post", currentStore.value);
    }
    if (res.success) { storeId = res!.value.id; }
    else {
      await AnybillLogger.instance.error(
        "POST call failed during store addition",
        res instanceof Error ? res : new Error(res.toString()),
        "stores store",
      );
      return res;
    }

    // get updated store list
    let single: any;
    if (id) {
      single = await useTypedFetch<AnybillResult<StoreDto>>("/vendorcustomerSpecificStoreSpecificService/get", {
        vendorCustomerId: id,
        storeId,
      });
    }
    else {
      single = await useTypedFetch<AnybillResult<StoreDto>>("/storeSpecificService/get", storeId);
    }

    if (single.success) {
      stores.value!.push(res.value);
      _summarizeStores(single.value);
      totalStoreCount.value++;
    }
    else {
      await AnybillLogger.instance.error(
        "Population call of a single store failed",
        new Error(single ?? "unknown"),
        "stores store",
      );
    }
  }

  /**
   * update store data
   * If id is set vendor customer is activated
   * TODO: Define global state customer isActive
   */
  async function updateStore({ id, logo }: { id: string | undefined | null; logo: File | null }): Promise<AnybillResult<void>> {
    let failures: boolean = false;

    // update store data
    if (id) {
      const updateResult = await VendorCustomerProvider.instance.updateStore(id, currentStore.value!.id, new CreateStoreDetailDto(currentStore.value!.storeDetail));
      if (updateResult.success) {
        const refreshedStore = updateResult.value;
        _updateStore(refreshedStore);
      }
      else {
        await AnybillLogger.instance.error(
          `Error called during deletion of store icon of store ${currentStore.value!.id}`,
          new Error(`[${updateResult.errorCode}] ${updateResult.errorMessage}` ?? "missing message"),
          "stores store",
        );
        failures = true;
      }
    }
    else {
      try {
        await useTypedFetch("/storeSpecificService/put", {
          body: new StoreDetailDto(currentStore.value!.storeDetail),
          storeId: currentStore.value!.id,
        });
      }
      catch (error) {
        await AnybillLogger.instance.error(
          `Update call for store ${currentStore.value!.id}`,
          error instanceof Error ? error : new Error(error as any ?? "unknown"),
          "stores store",
        );
        failures = true;
      }
    }
    // update logo/company image
    if (logo) {
      try {
        await storeIconUpload({ storeId: currentStore.value!.id, file: logo });
      }
      catch (error) {
        await AnybillLogger.instance.error(
          `Error called during deletion of store icon of store ${currentStore.value!.id}`,
          error instanceof Error ? error : new Error(error as any ?? "unknown"),
          "stores store",
        );
        failures = true;
      }
    }
    else if (!logo && !currentStore.value!.storeDetail.iconResource) {
      try {
        await storeIconDelete({ storeId: currentStore.value!.id });
      }
      catch (error) {
        await AnybillLogger.instance.error(
          `Error called during deletion of store icon of store ${currentStore.value!.id}`,
          error instanceof Error ? error : new Error(error as any ?? "unknown"),
          "stores store",
        );
        failures = true;
      }
    }

    if (!id) {
      const refreshedStore = await useTypedFetch<AnybillResult<StoreDto>>("/storeSpecificService/get", { storeId: currentStore.value!.id });
      if (refreshedStore.success)
        _updateStore(refreshedStore.value);
    }

    storeCreationLogo.value = null;
    currentStore.value = null;

    if (!failures)
      return AnybillResultHelper.success(void {}, 200);

    return AnybillResultHelper.failure(null, 0, null);
  }

  async function removeStore({ store, id }: { store: StoreDto; id: string | undefined | null }) {
    const storeId = store.id;
    try {
      if (id)
        await useTypedFetch("/vendorcustomerSpecificStoreService/delete", { body: store, vendorCustomerId: id });

      else
        await useTypedFetch("/storeService/delete", { storeId });
    }
    catch (error) {
      await AnybillLogger.instance.error(
        `Error called during deletion of store ${store.id}`,
        error instanceof Error ? error : new Error(error as any ?? "unknown"),
        "stores store",
      );
    }
    _removeStore(store);
    _decreaseStoreCount();
  }

  async function _uploadCompanyLogo(storeId: string, image: File) {
    const res = await storeIconUpload({ storeId, file: image });
    if (!res.success) {
      await AnybillLogger.instance.error(
        "Error called during uploading the company image",
        new Error(res.errorMessage ?? ""),
        "stores store",
      );
    }
  }

  function _updateStore(store: StoreDto) {
    const clone = ld.cloneDeep(stores.value);
    if (!clone)
      return;
    const toReplaceInd = clone!.findIndex(c => c.id === store.id);
    clone![toReplaceInd] = store;
    stores.value = clone;
  }

  function _removeStore(store: StoreDto) {
    if (!stores.value)
      return;
    stores.value = stores.value!.filter(c => c.id !== store.id);
  }

  function _decreaseStoreCount() {
    totalStoreCount.value--;
  }

  // Todo: create computed totalStores
  function _summarizeStores(_stores: StoreDto[] | null) {
    if (stores.value === null)
      stores.value = [];

    stores.value = stores.value.concat(_stores ?? []);
  }

  return {
    populate,
    populateAllStores,
    addStore,
    createStore,
    setSortedStores,
    getAllStores,
    updateStore,
    removeStore,
    reset,
    initialized,
    storeCreationLogo,
    hasStores,
    stores,
    activeStores,
    unavailable,
    loading,
    // indicates wheter populateAll is running or not
    allLoading,
    totalStoreCount,
    currentStore,
    isEdit,
  };
});

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useStoreModule, import.meta.hot));
